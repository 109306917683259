import { Tree } from './Node'
import { serializeKey } from './utilities'
import Node from './Node'
import { Retry } from './retryTask'

interface Options {
  /** How long the data remains fresh */
  staletime?: number
  /** Condition for retry fetch  */
  retry?: Retry
}

const STALETIME = 60 * 1000 * 60 * 24
const RETRY = true

export default class CacheClient {
  store: Tree = {}
  staletime: number
  retry: Retry

  constructor(options?: Options) {
    this.staletime = options?.staletime ?? STALETIME
    this.retry = options?.retry ?? RETRY
  }

  /**
   * Get interface for interacting with cached content
   * @param key - Query key
   * @returns Node
   */
  getNode = <T = any, M = any>(key: unknown): Node<T, M> => {
    const array = Array.isArray(key) ? key : [key]
    const trimmed = [...array]
    while (trimmed.length && trimmed.at(-1) === undefined) {
      --trimmed.length
    }

    const fn = (tree: Tree, [thisKey, ...restKeys]: unknown[]): Node => {
      const serializedKey = serializeKey(thisKey)
      const node = tree[serializedKey] ?? new Node()
      tree[serializedKey] = node
      if (!restKeys.length) return node
      const nextTree = node.tree ?? {}
      node.tree = nextTree
      return fn(nextTree, restKeys)
    }

    return fn(this.store, trimmed) as Node<T, M>
  }

  /**
   * Get query data from cache
   * @param key - Query key
   * @returns Data
   */
  getData = <T = any>(key: unknown) => this.getNode<T>(key).getData()

  /**
   * Write query data to cache and notify listeners
   * @param key - Query key
   * @param data - Data
   * @depracated meta
   */
  setData = (key: unknown, data: unknown) => {
    this.getNode(key).setData(data)
  }

  /**
   * Marks query as stale. Will trigger a refetch when query becomas active.
   * @param key - Query key
   */
  invalidate = (key?: unknown) => {
    if (key === undefined) {
      return Object.values(this.store).forEach((node) => node.invalidate())
    }
    this.getNode(key).invalidate()
  }

  /**
   * Clear cache
   * @param options - Options
   */
  clear = ({ key, refetch = false }: ClearArg = {}) => {
    if (key === undefined) {
      return Object.values(this.store).forEach((node) => node.clear(refetch))
    }
    this.getNode(key).clear(refetch)
  }

  /**
   * Throw away data that is being fetched
   * @param key - Query key
   */
  cancel = (key?: unknown) => {
    this.getNode(key).cancel()
  }
}

interface ClearArg {
  /** Query key */
  key?: unknown
  /** Trigger refetch or not (default: false) */
  refetch?: boolean
}
