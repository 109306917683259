import { useEffect, useRef, useState } from 'react'

// Like useState but the initializing value is observed and will override state if it changes
// Useful when state need to be reinitialized within the component lifecycle by an external factor
// debounce initializingDependency for a specific time span

interface Options<T> {
  // ms to debounce dependency change
  debounce?: number
  onChange?: (value: T) => void
}

const useVolatileState = <T>(
  initializingDependency: T,
  { debounce, onChange }: Options<T> = {}
) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>(undefined)
  const state = useState(initializingDependency)
  const [value = initializingDependency, setValue] = state
  const change = (value: T) => {
    setValue(value)
    onChange?.(value)
  }
  const ref = useRef({ change })
  ref.current = { change }

  useEffect(() => {
    if (!debounce) {
      ref.current.change(initializingDependency)
      return
    }
    timeout.current = setTimeout(() => {
      ref.current.change(initializingDependency)
    }, debounce)
    return () => {
      clearTimeout(timeout.current)
    }
  }, [setValue, debounce, initializingDependency, ref])

  if (!debounce) return state

  const setter: typeof setValue = (value) => {
    if (timeout.current) clearTimeout(timeout.current)
    setValue(value)
  }
  return [value, setter] as const
}

export default useVolatileState
