import { HttpRefreshTokenResponse } from 'lib'
import { readAccessToken, storeAccessToken } from './cookies'
import ApiClient, { isUnauthorized } from '../ApiClient'
import login from './login'

const REFRESH_ENDPOINT = '/refresh'

class AuthClient {
  get = async (path: string) =>
    this.auth((headers: Headers) => new ApiClient(headers).get(path))

  post = (path: string, body: BodyInit) =>
    this.auth((headers: Headers) => new ApiClient(headers).post(path, body))

  private auth = async (
    request: (headers: Headers) => Promise<any>
  ): Promise<any> => {
    let token = readAccessToken()
    if (!token) {
      const { access_token } = await refreshGrant()
      token = access_token
      storeAccessToken(token)
    }
    try {
      const response = await request(buildAuthHeaders(token))
      return response
    } catch (error) {
      if (isUnauthorized(error)) {
        const { access_token } = await refreshGrant()
        storeAccessToken(access_token)
        const response = await request(buildAuthHeaders(access_token))
        return response
      }
      throw error
    }
  }
}

export default AuthClient

const refreshGrant = async (): Promise<HttpRefreshTokenResponse> => {
  try {
    const response = await new ApiClient().get(REFRESH_ENDPOINT)
    return response
  } catch (error) {
    if (isUnauthorized(error)) return login()
    throw error
  }
}

const buildAuthHeaders = (accessToken: string) =>
  new Headers({ Authorization: `Bearer ${accessToken}` })
