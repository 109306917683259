import Cookies from 'js-cookie'

const FINANS_ACCESS = 'FINANS_ACCESS'

export const readAccessToken = () => Cookies.get(FINANS_ACCESS)

export const deleteAccessToken = () => Cookies.remove(FINANS_ACCESS)

export const parseToken = (jwt: string) => {
  const [header, payload] = jwt
    .split('.')
    .slice(0, 2)
    .map((b64) => JSON.parse(atob(b64)))
  return { header, payload }
}

export const storeAccessToken = (jwt: string) => {
  try {
    const { exp } = parseToken(jwt).payload
    Cookies.set(FINANS_ACCESS, jwt, {
      sameSite: 'Strict',
      expires: new Date(1000 * Number(exp ?? 0)),
      secure: true,
    })
  } catch (error) {
    console.log('Could not store access token', error)
  }
}
