import {
  HttpQueryActorsResponse,
  HttpQueryMoviesResponse,
  QueryActors,
  QueryMovies,
} from 'lib'
import AuthClient from './AuthClient'

class Api {
  actors = (
    query: Record<QueryActors, string>
  ): Promise<HttpQueryActorsResponse> =>
    new AuthClient().get(`/actors?${new URLSearchParams(query).toString()}`)

  movies = (
    query: Record<QueryMovies, string>
  ): Promise<HttpQueryMoviesResponse> =>
    new AuthClient().get(`/movies?${new URLSearchParams(query).toString()}`)
}

export default Api
