import { QueryLogout } from 'lib'
import { deleteAccessToken } from './cookies'
import env from '../env'

const { REACT_APP_BACKEND_URL } = env

const logout = () => {
  deleteAccessToken()
  const url = new URL(`${REACT_APP_BACKEND_URL}/logout`)
  url.searchParams.append(QueryLogout.Back, global.location.origin)
  global.location.href = url.href
  throw Error('Unreachable code')
}

export default logout
