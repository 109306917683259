import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { QueryRedirect } from 'lib'
import { login, readAccessToken, storeAccessToken } from '../AuthClient'

const useCaptureToken = () => {
  const [search, setSearch] = useSearchParams()
  const token = search.get(QueryRedirect.Token)

  useEffect(() => {
    if (!token) return
    storeAccessToken(token)
    setSearch((params) => {
      const query = new URLSearchParams(params)
      query.delete(QueryRedirect.Token)
      return query
    })
  }, [token, setSearch])

  useEffect(() => {
    if (!readAccessToken()) {
      login()
    }
  }, [])
}

export default useCaptureToken
