import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import Node, { Callbacks, QueryResult } from './Node'
import { useCacheClient } from './CacheClientProvider'
import { Retry } from './retryTask'
import useStatic from './useStatic'

export interface Options<T> extends Callbacks<T> {
  /** Passive query. Will not fetch but respond to cache updates */
  inactive?: boolean
  /** Unmounted query. Will not fetch or respond to cache updates */
  disabled?: boolean
  /** If key changes, data will be fetched in background (isLoading will be false and old data will persist until new data is at hand) */
  paginated?: boolean
  /** Serve data from from cache until stale */
  staletime?: number
  /** Retry condition */
  retry?: Retry
}

interface Result<T> {
  /** Data */
  data?: T
  /** Error resolving data */
  error: unknown
  /** Data is fetched */
  isFetching: boolean
  /** Data is beinng fetched for the first time */
  isLoading: boolean
  /** Data wes fetched successfully */
  isSuccess: boolean
  /** Interface for interacting with cached content */
  node: Node<T>
  /** Clear pagination buffer (Paginated data will not persist during next fetch) */
  resetPagination: () => void
}

/**
 * Resolve data with a unique identifier. Also provides various status indicators
 * @param key - Unique key for this query
 * @param resolver - Async function resolving the data
 * @param options - Options
 * @returns Result
 */
const useQuery = <T>(
  key: unknown,
  resolver: () => Promise<T>,
  options: Options<T> = {}
): Result<T> => {
  const cacheClient = useCacheClient()
  const node = cacheClient.getNode<T>(key)
  const [query = { ...node.item }, setState] = useState<
    QueryResult<T> | undefined
  >(node.item)
  const { cache, signal, error } = query
  const {
    inactive = false,
    disabled = false,
    paginated = false,
    retry = cacheClient.retry,
    staletime = cacheClient.staletime,
    onSuccess,
    onError,
    onSettled,
  } = options
  const ref = useStatic({ resolver, retry })
  const callbacks = useStatic({ onSuccess, onSettled, onError })
  const buffer = useRef<T>(undefined)
  const data = cache?.data ?? buffer.current

  // Initialization/key change
  useLayoutEffect(() => {
    const { item } = node
    setState(item)
    if (disabled || !paginated) buffer.current = undefined
    if (disabled) return
    const onSuccess = (data: T) => {
      if (paginated) buffer.current = data
      callbacks.current.onSuccess?.(data)
    }
    const onSettled = () => {
      callbacks.current.onSettled?.()
    }
    const unregister = node.register(setState, {
      onSuccess(data: T) {
        if (paginated) buffer.current = data
        callbacks.current.onSuccess?.(data)
      },
      onError(error: unknown) {
        buffer.current = undefined
        callbacks.current.onError?.(error)
      },
      onSettled() {
        callbacks.current.onSettled?.()
      },
    })
    if (item?.cache?.test(staletime)) {
      onSuccess(item.cache.data)
      onSettled()
    } else if (!item?.pending && !inactive) {
      const { resolver, retry } = ref.current
      node.download(resolver, retry)
    }
    return unregister
  }, [callbacks, node, inactive, disabled, staletime, paginated, ref])

  const isInactive = disabled || inactive
  const settled = cache || error
  const shouldRefetch = !isInactive && (!!signal || !settled)

  // Respond to invalidation/clear
  useEffect(() => {
    if (!shouldRefetch || signal?.aborted || node.item?.pending) return
    const { resolver, retry } = ref.current
    node.download(resolver, retry)
  }, [node, shouldRefetch, signal, ref])

  const isIdle = !node.item?.pending
  const isFetching = !isIdle || shouldRefetch
  const isLoading = isFetching && !settled && data === undefined
  const isSuccess = !isFetching && !!cache
  const resetPagination = useCallback(() => {
    buffer.current = undefined
  }, [buffer])

  return {
    data,
    error,
    isFetching,
    isLoading,
    isSuccess,
    node,
    resetPagination,
  }
}

export default useQuery
