export const sortProperties = (obj: unknown): unknown => {
  if (!obj || typeof obj !== 'object') return obj
  if (Array.isArray(obj)) return obj.map(sortProperties)

  const entries = Object.entries(obj)
    .sort(([p1], [p2]) => (p1 > p2 ? 1 : -1))
    .map(([key, value]) => [key, sortProperties(value)])

  return Object.fromEntries(entries)
}

/**
 * Sort properties and stringify
 * @param key - Key
 * @returns string
 */
export const serializeKey = (key: unknown) => {
  const sorted = sortProperties(key)
  return typeof sorted === 'string' ? sorted : JSON.stringify(sorted)
}
