import { createContext, useContext, FC, ReactNode } from 'react'
import CacheClient from './CacheClient'

interface Props {
  children?: ReactNode
  value: CacheClient
}

const CacheClientContext = createContext<CacheClient | undefined>(undefined)

const CacheClientProvider: FC<Props> = ({ children, value }) => (
  <CacheClientContext.Provider value={value}>
    {children}
  </CacheClientContext.Provider>
)

export default CacheClientProvider

export const useCacheClient = () => {
  const cache = useContext(CacheClientContext)
  if (!cache) throw Error('Must wrap with CacheProvider to use cache')
  return cache
}
