import { isNetworkError } from './ApiClient'
import { CacheClient, CacheClientProvider } from './fetch'
import Main from './Main'
import useAuthentication from './utilities/useAuthentication'

const cacheClient = new CacheClient({ retry: isNetworkError })

const App: React.FC = () => {
  useAuthentication()
  return (
    <CacheClientProvider value={cacheClient}>
      <Main />
    </CacheClientProvider>
  )
}

export default App
