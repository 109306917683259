import { useQuery, Options } from '../fetch'
import Api from '../Api'
import { QueryMovies, HttpQueryMoviesResponse } from 'lib'

type Query = Record<QueryMovies, string>

export type Key = ['movies', Query]

const useMovies = (
  query: Query,
  options: Options<HttpQueryMoviesResponse> = {}
) => {
  const key: Key = ['movies', query]
  return useQuery(key, () => new Api().movies(query), options)
}

export default useMovies
