import { FormEventHandler, Fragment } from 'react'
import useMovies from './queries/useMovies'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useVolatileState from './utilities/useVolatileState'
import { logout } from './AuthClient'

const Main: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const urlSearch = searchParams.get('search') ?? ''
  const [fieldSearch] = useVolatileState(urlSearch, { debounce: 500 })

  const { data: movies } = useMovies(
    { title: fieldSearch },
    { disabled: !fieldSearch }
  )

  const onInput: FormEventHandler<HTMLInputElement> = (event) => {
    navigate(`?search=${event.currentTarget.value}`)
  }

  return (
    <div className="container">
      <div className="mt-5">
        <button className="btn btn-secondary" onClick={logout}>
          Logout
        </button>

        <h1 className="display-6 py-4">Movies</h1>
        <input type="text" onInput={onInput} value={urlSearch} autoFocus />
        {movies?.map((movie) => (
          <div key={movie.id} className="pt-5">
            <h2 className="lead fw-bold pb-4">{movie.title}</h2>
            <p className="fw-bold">Director</p>
            <p>{movie.director}</p>
            <p className="fw-bold">Genre</p>
            <p>{movie.genre}</p>
            <p className="fw-bold">Year</p>
            <p>{movie.year}</p>
            <p className="fw-bold">Roles</p>
            <div className="row">
              {movie.roles.map((role) => (
                <Fragment key={role.id}>
                  <span className="col-2">{role.actor.name}</span>
                  <span className="col-2 fst-italic">{role.name}</span>
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Main
