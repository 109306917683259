import wait from './wait'

const MAX_RETRY_INTERVAL = 30 * 1000

export type Retry =
  | boolean
  | number
  | ((error: unknown, failCount: number) => boolean)

export interface Options {
  /** Retry condition. boolean (toggle), number (max attempts) or function (predicate) */
  retry?: Retry
  /** To cancel task */
  signal?: AbortSignal
}

type Task<T> = (abortSignal?: AbortSignal) => Promise<T>

/**
 * Invoke async function repeatedly until success
 * @param task - Async function
 * @param options - Options
 * @return Promise
 */
const retryTask = async <T>(
  task: Task<T>,
  { retry = true, signal }: Options = {}
) => {
  let retryCount = 0

  while (true) {
    try {
      const data = await task(signal)
      if (signal?.aborted) throw signal.reason
      return data
    } catch (error) {
      if (signal?.aborted) throw signal.reason
      if (!shouldRetry(retryCount++, retry, error)) throw error
      const delay = Math.min(MAX_RETRY_INTERVAL, 1000 * 2 ** retryCount)
      await wait(delay)
    }
  }
}

export default retryTask

const shouldRetry = (retryCount: number, retry: Retry, error: unknown) => {
  if (typeof retry === 'number') {
    return retryCount < retry
  }
  if (typeof retry === 'function') {
    return retry(error, retryCount)
  }
  return retry
}
