export default class Timestamped<T> {
  createdAt: number
  data: T

  constructor(data: T) {
    this.data = data
    this.createdAt = Date.now()
  }

  test = (staletime: number) => this.createdAt + staletime > Date.now()
}
