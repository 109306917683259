import env from './env'

const UNAUTHORIZED = 'Unauthorized'
const BAD_REQUEST = 'Bad request'
const FORBIDDEN = 'Forbidden'
const NOT_FOUND = 'Not found'
const INTERNAL_SERVER_ERROR = 'Internal server error'

const { REACT_APP_BACKEND_URL } = env

class ApiClient {
  constructor(public headers = new Headers()) {}

  get = async (path: string) => this.call('GET', path)

  post = async (path: string, body: BodyInit) => this.call('POST', path, body)

  protected call = async (
    method: string,
    path: string,
    body?: BodyInit | null
  ) => {
    const headers = new Headers(this.headers)
    headers.append('accept', 'application/json')

    const response = await fetch(`${REACT_APP_BACKEND_URL}${path}`, {
      method,
      body,
      credentials: 'include',
      mode: 'cors',
      headers,
    })

    if (!response.ok) {
      if (response.status === 400) {
        throw Error(BAD_REQUEST)
      }
      if (response.status === 401) {
        throw Error(UNAUTHORIZED)
      }
      if (response.status === 403) {
        throw Error(FORBIDDEN)
      }
      if (response.status === 404) {
        throw Error(NOT_FOUND)
      }
      if (response.status === 500) {
        throw Error(INTERNAL_SERVER_ERROR)
      }
    }

    return response.json()
  }
}

export default ApiClient

export const isUnauthorized = (error: unknown): error is Error =>
  error instanceof Error && error.message === UNAUTHORIZED

export const isBadRequest = (error: unknown): error is Error =>
  error instanceof Error && error.message === BAD_REQUEST

export const isForbidden = (error: unknown): error is Error =>
  error instanceof Error && error.message === FORBIDDEN

export const isNotFound = (error: unknown): error is Error =>
  error instanceof Error && error.message === NOT_FOUND

export const isInternalServerError = (error: unknown): error is Error =>
  error instanceof Error && error.message === INTERNAL_SERVER_ERROR

export const isNetworkError = (error: unknown) => error instanceof TypeError
