import { QueryLogin } from 'lib'
import env from '../env'

const { REACT_APP_BACKEND_URL } = env

const login = () => {
  const url = new URL(`${REACT_APP_BACKEND_URL}/login`)
  url.searchParams.append(QueryLogin.Back, global.location.href)
  global.location.href = url.href
  throw Error('Unreachable code')
}

export default login
