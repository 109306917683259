import Timestamped from "./Timestamped";
import { default as retryTask, Retry } from "./retryTask";

const RETRY = true;

export type Listener<T> = (item?: QueryResult<T>) => void;

export type Tree = Record<string, Node<unknown>>;

export interface Item<T> {
  cache?: Timestamped<T>;
  pending?: boolean;
  signal?: AbortSignal;
}

export interface QueryResult<T> extends Item<T> {
  error?: unknown;
}

export interface Callbacks<T = any, M = any> {
  /**
   * Callback called when data has been fetched
   * @param value - Data
   * @depracated meta
   */
  onSuccess?: (value: T, meta?: M) => void;
  /**
   * Callback called if an error occurs
   * @param error - Error
   */
  onError?: (error: unknown) => void;
  /** Callback called when query settles */
  onSettled?: () => void;
}

interface Subscriber<T> {
  listener: Listener<T>;
  callbacks?: Callbacks<T>;
}

export default class Node<T = any, M = any> {
  item?: Item<T>;
  subscribers: Subscriber<T>[] = [];
  tree?: Tree;
  abortController = new AbortController();

  register = (listener: Listener<T>, callbacks?: Callbacks<T>) => {
    const { subscribers } = this;
    const subscriber = { listener, callbacks };
    subscribers.push(subscriber);
    return () => {
      subscribers.splice(subscribers.indexOf(subscriber), 1);
      if (!subscribers.length) {
        this.cancel();
        this.item = { ...this.item, pending: undefined };
      }
    };
  };

  #emit = (query?: QueryResult<T>) => {
    this.subscribers.forEach(({ listener }) => {
      listener(query);
    });
  };

  #onError = (error: unknown) => {
    this.subscribers.forEach(({ callbacks }) => {
      callbacks?.onError?.(error);
    });
  };

  #onSuccess = (data: T, meta?: M) => {
    this.subscribers.forEach(({ callbacks }) => {
      callbacks?.onSuccess?.(data, meta);
    });
  };

  #onSettled = () => {
    this.subscribers.forEach(({ callbacks }) => {
      callbacks?.onSettled?.();
    });
  };

  getData = () => this.item?.cache?.data;

  setData = (data: T) => {
    this.#onSuccess(data);
    this.#onSettled();
    this.#setItem({ cache: new Timestamped(data) });
  };

  #error = (error: unknown) => {
    delete this.item;
    this.#onError(error);
    this.#onSettled();
    this.#emit({ error });
  };

  #setItem = (item?: Item<T>) => {
    this.item = item;
    this.#emit(item);
  };

  invalidate = () => {
    this.#setItem({
      cache: this.item?.cache,
      signal: this.abortController.signal,
    });
    if (this.tree) {
      Object.values(this.tree).forEach((node) => node.invalidate());
    }
  };

  clear = (refetch = false) => {
    if (!refetch) {
      this.item = undefined;
    } else {
      this.#setItem(undefined);
    }
    if (this.tree) {
      Object.values(this.tree).forEach((node) => node.clear(refetch));
    }
  };

  cancel = () => {
    this.abortController.abort();
    this.abortController = new AbortController();
  };

  isEmpty = () => {
    return !this.item?.cache;
  };

  download = async (resolver: () => Promise<T>, retry: Retry = RETRY) => {
    const { signal } = this.abortController;
    this.#setItem({ cache: this.item?.cache, pending: true });
    try {
      const data = await retryTask(resolver, { retry, signal });
      if (signal?.aborted) return;
      this.setData(data);
      return data;
    } catch (error) {
      if (signal?.aborted) return;
      this.#error(error);
    }
  };
}
